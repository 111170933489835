import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '@components/Layout'
import Hero from '@components/Hero'
import Pager from '@components/Pager'
import { graphql, Link } from 'gatsby'
import { autoTime, absoluteTime, shouldUseRelativeTime, relativeTime } from '../util/time'
import theme, { makeGradient } from '../../config/theme'
import styled from 'styled-components'
import Color from 'color'

const BlogPostItemWrapper  = styled.article`
  margin: 1rem;
  background: ${makeGradient(theme.colors.dark, '135deg')};
  color: ${theme.colors.light.color};

  /* FIXME: copy-pasted from project */
  padding: 2rem;
  border-radius: 1rem;
  z-index: 5;

  box-shadow: 0 1rem 5rem -1rem rgba(0,0,0,0.5);
  transform: translateY(0);
  transition: all 400ms ease-in-out;
`

const BlogPostTitle = styled(({ children, ...props }) =>
  <Link {...props}><h1>{children}</h1></Link>
)`
  text-decoration: none;
  color: inherit;

  transition: .2s all;
  :hover {
    color: ${Color(theme.colors.primary.color).lighten(.3).string()}
  }
`
const BlogPostDetails = styled.div`
  color: ${theme.colors.light.muted.color};
  font-size: 75%;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  & > :not(:last-child)::after {
    margin: 0 .5em;
    content: '—';
  }
`

const DateDisplay = ({ date }) =>
  // shouldUseRelativeTime(date)
  //   ? <UnstyledAbbr title={absoluteTime(date)}>{relativeTime(date, true)}</UnstyledAbbr>
  //   : <span>{absoluteTime(date)}</span>
  <span title={shouldUseRelativeTime(date) ? absoluteTime(date) : null}>{autoTime(date)}</span>

const BlogPostItem = ({ node }) =>
  <BlogPostItemWrapper>
    <BlogPostTitle to={node.fields.slug}>{node.frontmatter.title}</BlogPostTitle>
    <BlogPostDetails>
      {/* <span>{autoTime(node.frontmatter.date, true)}</span> */}
      <DateDisplay date={node.frontmatter.date} />
      <span>{node.timeToRead} min read</span>
    </BlogPostDetails>
    <div>{node.excerpt}</div>
  </BlogPostItemWrapper>

BlogPostItem.propTypes = {
  node: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    timeToRead: PropTypes.number.isRequired,
    excerpt: PropTypes.string.isRequired,
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }).isRequired,
  })
}

const PostsContainer = styled.section`
  padding: 0 2rem;
  @media not all and (min-width: 480px) {
    padding: 0;
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-auto-rows: 1fr;
`

const BlogList = ({
  pageContext: {
    pageNum,
    numPages
  },
  data
}) =>
  <Layout>
    <Hero><h1>Blog</h1></Hero>
    <PostsContainer>
      {data.allMarkdownRemark.edges.map(({node}) =>
        <BlogPostItem node={node} title={node.frontmatter.title} date={node.frontmatter.date}
          timeToRead={node.timeToRead} slug={node.fields.slug} key={node.fields.slug}/>
      )}
    </PostsContainer>
    <Pager pages={numPages} page={pageNum} genURL={i=> i === 1 ? '/blog' : `/blog/page/${i}`}/>
  </Layout>

BlogList.propTypes = {
  pageContext: PropTypes.shape({
    pageNum: PropTypes.number.isRequired,
    numPages: PropTypes.number.isRequired,
  }),
  data: PropTypes.object.isRequired
}

export default BlogList

export const pageQuery = graphql`
  query BlogPosts(
    $limit: Int!, $skip: Int!
    $draftFilter: BooleanQueryOperatorInput!
  ) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, fields___slug] }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: { draft: $draftFilter }
        fields: {
          sourceInstanceName: { eq: "blog" }
        }
      }
     ) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          excerpt
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`
