import React from 'react'
import PropTypes from 'prop-types'
import Button from '@components/Button'
import styled from 'styled-components'
import _ from 'lodash'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 100;
  position: relative;
`

const PagerButton = styled(Button)`
  min-width: 2.5em;
  text-align: center;
  background-color: #fff;
`

const Pager = ({
  pages,
  page,
  genURL,
  noAutohide,
  ...props
}) =>
  (!noAutohide && pages === 1) ? null :
  <Wrapper {...props}>
    {_.range(pages).map(i=>i+1).map(i =>
      <PagerButton as={Link} to={genURL(i)} active={i === page} key={i} inverse group noTransparent>
        {i}
      </PagerButton>
    )}
  </Wrapper>

Pager.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  genURL: PropTypes.func.isRequired,
  noAutohide: PropTypes.bool,
}

export default Pager
